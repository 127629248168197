import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 解决路由跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [{
  path: '/',
  redirect: {
    name: 'index'
  }
},
{
  path: '/',
  name: 'index',
  component: () =>
    import( /* webpackChunkName: "index" */ '../views/frontPage/index.vue'),
  meta: {
    title: '首页'
  },
},
{
  path: '/smartManagement',
  name: 'smartManagement',
  component: () =>
    import( /* webpackChunkName: "smartManagement" */ '../views/culturalTourism/smartManagement.vue'),
  meta: {
    title: '智慧管理'
  },
},
{
  path: '/smartProtection',
  name: 'smartProtection',
  component: () =>
    import( /* webpackChunkName: "smartManagement" */ '../views/culturalTourism/smartProtection.vue'),
  meta: {
    title: '智慧保护'
  }
},
{
  path: '/smartService',
  name: 'smartService',
  component: () =>
    import( /* webpackChunkName: "smartManagement" */ '../views/culturalTourism/smartService.vue'),
  meta: {
    title: '智慧服务'
  }
},
{
  path: '/smartCity',
  name: 'smartCity',
  component: () =>
    import( /* webpackChunkName: "smartCity" */ '../views/enterprise/smartCity.vue'),
  meta: {
    title: '智慧城市'
  }
},
{
  path: '/glsClmBim',
  name: 'glsClmBim',
  component: () =>
    import( /* webpackChunkName: "glsClmBim" */ '../views/enterprise/glsClmBim.vue'),
  meta: {
    title: 'GIS+GIM+BIM'
  }
},
{
  path: '/DigitalGovernment',
  name: 'DigitalGovernment',
  component: () =>
    import( /* webpackChunkName: "DigitalGovernment" */ '../views/enterprise/DigitalGovernment.vue'),
  meta: {
    title: '数字政务'
  }
},
{
  path: '/exhibition',
  name: 'exhibition',
  component: () =>
    import( /* webpackChunkName: "exhibition" */ '../views/exhibit/exhibition.vue'),
  meta: {
    title: '展览展示'
  }
},
{
  path: '/videoProduction',
  name: 'videoProduction',
  component: () =>
    import( /* webpackChunkName: "videoProduction" */ '../views/exhibit/videoProduction.vue'),
  meta: {
    title: '视频制作'
  }
},
{
  path: '/virtual',
  name: 'virtual',
  component: () =>
    import( /* webpackChunkName: "virtual" */ '../views/exhibit/virtualDisplay.vue'),
  meta: {
    title: '虚拟展示'
  }
},
{
  path: '/desktop',
  name: 'desktop',
  component: () =>
    import( /* webpackChunkName: "virtual" */ '../views/exhibit/desktopDisplay.vue'),
  meta: {
    title: '虚拟展示'
  }
},
{
  path: '/exhibit',
  name: 'exhibit',
  component: () =>
    import( /* webpackChunkName: "exhibit" */ '../views/successCase/exhibit.vue'),
  meta: {
    title: '展览'
  }
},
{
  path: '/activity',
  name: 'activity',
  component: () =>
    import( /* webpackChunkName: "activity" */ '../views/successCase/activity.vue'),
  meta: {
    title: '活动'
  }
},
{
  path: '/3Dmodel',
  name: '3Dmodel',
  component: () =>
    import( /* webpackChunkName: "3Dmodel" */ '../views/successCase/3Dmodel.vue'),
  meta: {
    title: '三维模型'
  }
},
{
  path: '/software',
  name: 'software',
  component: () =>
    import( /* webpackChunkName: "virtual" */ '../views/productCenter/software.vue'),
  meta: {
    title: '软件产品'
  }
},
{
  path: '/hardware',
  name: 'hardware',
  component: () =>
    import( /* webpackChunkName: "virtual" */ '../views/productCenter/hardware.vue'),
  meta: {
    title: '硬件产品'
  }
},
{
  path: '/companyProfile',
  name: 'companyProfile',
  component: () =>
    import( /* webpackChunkName: "companyProfile" */ '../views/about/companyProfile.vue'),
  meta: {
    title: '公司简介'
  }
},
{
  path: '/connect',
  name: 'connect',
  component: () =>
    import( /* webpackChunkName: "connect" */ '../views/about/connect.vue'),
  meta: {
    title: '联系我们'
  }
},
{
  path: '/news',
  name: 'news',
  component: () =>
    import( /* webpackChunkName: "news" */ '../views/about/news.vue'),
  meta: {
    title: '新闻资讯'
  }
}
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router