const state = {
    // ip:'http://192.168.1.245:8095',
    ip:'http://58.63.50.170:8095', // 58服务器
    show:false,
    id:'',
    routefullPath:''
}
const mutations = {
    SET_THOW:(state,data)=>{
        state.show = data
    },
    SET_ID:(state,data)=>{
        state.id = data
    },
    SET_routefullPath:(state,data)=>{
        state.routefullPath = data
    }
}
const actions = {}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}