import router from './router'
// import store from './store'
import getPageTitle from '@/utils/get-page-title'

// 路由前置守卫
router.beforeEach((to, from, next) => {
    document.title = getPageTitle(to.meta.title)
    next()
})
// 路由后置守卫
router.afterEach(() => {
})