import {
    Collapse,
    CollapseItem,
    Alert,
    Button,
    Container,
    Footer,
    Form,
    FormItem,
    Header,
    Input,
    Main,
    Message,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
    Carousel,
    CarouselItem,
    Dialog,
    Loading,
    Radio,
    Checkbox,
    Autocomplete,
    Breadcrumb,
    BreadcrumbItem,
    Tabs,
    TabPane,
    DatePicker,
    Select,
    Option,
    Table,
    TableColumn,
    Pagination,
    Upload,
    Cascader,
    Tooltip,
    CascaderPanel,
    MessageBox,
    RadioGroup
  } from 'element-ui'
  
  export default {
    install(Vue) {
      Vue.component(Collapse.name, Collapse)
      Vue.component(CollapseItem.name, CollapseItem)
      Vue.component(Alert.name, Alert)
      Vue.component(Button.name, Button)
      Vue.component(CascaderPanel.name, CascaderPanel)
      Vue.component(Tooltip.name, Tooltip)
      Vue.component(Cascader.name, Cascader)
      Vue.component(Upload.name, Upload)
      Vue.component(Pagination.name, Pagination)
      Vue.component(Table.name, Table)
      Vue.component(TableColumn.name, TableColumn)
      Vue.component(Select.name, Select)
      Vue.component(Option.name, Option)
      Vue.component(DatePicker.name, DatePicker)
      Vue.component(Tabs.name, Tabs)
      Vue.component(TabPane.name, TabPane)
      Vue.component(Breadcrumb.name, Breadcrumb)
      Vue.component(BreadcrumbItem.name, BreadcrumbItem)
      Vue.component(Form.name, Form)
      Vue.component(FormItem.name, FormItem)
      Vue.component(Input.name, Input)
      Vue.component(Container.name, Container)
      Vue.component(Header.name, Header)
      Vue.component(Footer.name, Footer)
      Vue.component(Main.name, Main)
      Vue.component(Dropdown.name, Dropdown)
      Vue.component(DropdownMenu.name, DropdownMenu)
      Vue.component(DropdownItem.name, DropdownItem)
      Vue.component(Row.name, Row)
      Vue.component(Col.name, Col)
      Vue.component(Carousel.name, Carousel)
      Vue.component(CarouselItem.name, CarouselItem)
      Vue.component(Dialog.name, Dialog)
      Vue.component(Radio.name, Radio)
      Vue.component(Checkbox.name, Checkbox)
      Vue.component(Autocomplete.name, Autocomplete)
      Vue.component(RadioGroup.name, RadioGroup)
      Vue.use(Loading.directive)
      Vue.prototype.$loading = Loading.service
      Vue.prototype.$message = Message
      Vue.prototype.$confirm = MessageBox.confirm
    }
  }
  