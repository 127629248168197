import request from '../utils/request'

// 获取轮播图
export function getBanner(){
    return request({
        url:'/yuan/external/searchBanner',
        method:'post'
    })
}

// 获取列表栏目
export function getColumns(){
    return request({
        url:'/yuan/external/searchColumns',
        method:'post'
    })
}

// 栏目内容
export function getColumnContent(data){
    return request({
        url:'/yuan/external/searchContent',
        method:'post',
        data
    })
}

// 产品中心(总的)
export function productCenter(data){
    return request({
        url:'/yuan/external/searchContentEx',
        method:'post',
        data
    })
}
// 智慧政企置顶数据
export function getSmart(data){
    return request({
        url:'/yuan/external/searchContentEr',
        method:'post',
        data
    })
}
// 获取上下页的内容
export function getdown(data){
    return request({
        url:'/yuan/external/getEntityFromContent',
        method:'post',
        data
    })
}

// 通过id查找实体
export function getEntityById(data){
    return request({
        url:'/yuan/external/getEntityFromContent',
        method:'post',
        data
    })
}

// 成功案例
export function getsearchHaiShiData(data){
    return request({
        url:'/yuan/external/searchHaiShiData',
        method:'post',
        data
    })
}