<template>
  <div id="app">
    <el-container>
      <el-header>
        <MHeader></MHeader>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <MFooter></MFooter>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import MHeader from '@/components/MHeader.vue'
import MFooter from '@/components/MFooter.vue'
export default {
  components: {
    MHeader,
    MFooter
  },
  data() {
    return {
      GetWindowInfos: {
        height: '',  //动态获取content高度
        width: ''	//动态获取content宽度
      }
    }
  },
  created() {
    window.addEventListener('resize', this.GetWindowInfo);
    this.GetWindowInfo() //页面创建时先调用一次
  },
  methods: {
    GetWindowInfo() {
      // 获取浏览器高度，减去顶部导航栏的值70（可动态获取）,再减去head-DIV高度值80
      this.GetWindowInfos.height = window.innerHeight;
      this.GetWindowInfos.width = window.innerWidth;
      if (this.GetWindowInfos.width < 750) {
        self.location.href = `http://m.yuanit.com.cn${this.$store.state.ip.routefullPath}`
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.GetWindowInfo)
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/icon/iconfont.css';

#app {
  margin: 0 auto;
}

html,
body {
  font-size: 16px;
}

.el-header,
.el-footer {
  color: #333;
  text-align: center;
  // height: 60px;
  // line-height: 60px;
}

.el-header {
  display: flex;
  align-items: center;
  height: 70px !important;
  line-height: 70px;
}

.el-footer {
  padding: 0;
}

.el-main {
  color: #333;
  text-align: center;
  // line-height: 160px;
  padding: 0;
}</style>
