<template>
  <div class="footer">
    <div class="footer-top">
      <div class="address">
        <div class="logo">
          <span class="icon">
            <i class="iconfont icon-dizhi1"></i>
          </span>
        </div>
        <div class="logo-text">
          <h5>公司地址</h5>
          <p>
            广州市黄埔区科丰路33号（金发科技创新产业园20栋B座10层1001房）
          </p>
        </div>
      </div>
      <i class="line"></i>
      <div class="telephone">
        <div class="logo">
          <span class="icon">
            <i class="iconfont icon-dianhuatianchong"></i>
          </span>
        </div>
        <div class="logo-text">
          <h5>联系电话</h5>
          <p>
            020-36686566&nbsp;&nbsp;&nbsp; 服务时间：9:00-18:00
          </p>
        </div>
      </div>
      <i class="line"></i>
      <div class="mail">
        <div class="logo">
          <span class="icon">
            <i class="iconfont icon-youjian"></i>
          </span>
        </div>
        <div class="logo-text">
          <h5>公司邮箱</h5>
          <p>
            36686566@163.com
          </p>
        </div>
      </div>
      <i class="line"></i>
      <div class="qr-code">
        <div class="logo">
          <span class="icon">
            <i class="iconfont icon-weixin"></i>
          </span>
        </div>
        <div class="code">
          <img src="../../src/assets/imgs/code.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      COPYRIGHT © 2022 广州市昱安信息技术有限公司 <a href="https://www.beianx.cn/info/256669903945797" target="_blank">粤ICP备12065840号</a>
    </div>
  </div>
</template>

<script>
  export default {};
</script>
<style lang="scss" scoped>
  .footer {
    .footer-top {
      height: 268px;
      line-height: 268px;
      background-color: #0266ba;
      opacity: 0.95;
      display: flex;
      justify-content: center;
      align-items: center;
      .address,
      .telephone,
      .mail,
      .qr-code {
        width: 410px;
        line-height: 156px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .ceshi {
        width: 234px;
        height: 234px;
      }
      h5 {
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 20px;
        color: #ffffff;
        margin: 10px 0 10px 0;
      }
      p {
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        padding: 0 30px;
      }
      .icon {
        display: block;
        border: 1px solid #ffffff;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        line-height: 43px;
        text-align: center;
      }
      .logo {
        width: 234px;
        height: 50px;
        line-height: 50px;
        margin-top: -50px;
        display: flex;
        justify-content: center;
      }
      .logo-text {
        height: 72px;
        h5 {
          font-size: 20px;
          font-weight: lighter;
        }
        p {
          font-size: 14px;
        }
      }
      i {
        font-size: 25px;
      }
      .code {
        width: 70px;
        height: 70px;
        line-height: 70px;
        img {
          margin-top: 10px;
          width: 100%;
          height: 100%;
        }
      }
      .line {
        width: 1px;
        height: 80px;
        background-color: #fff;
      }
    }
    .footer-bottom {
      height: 80px;
      background-color: #0266ba;
      line-height: 80px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
      text-align: center;
      a {
        color: #fff;
      }
    }
  }
</style>