<template>
  <div class="title">
    <div class="logo">
      <img src="../assets/imgs/logo.png" alt="" />
    </div>
    <!-- nav导航 -->
    <div class="list-tilte">
      <ul>
        <li>
          <div class="line"></div>
          <router-link :to="{ path: '/' }" class="front">首页</router-link>
        </li>
        <li v-for="(item, index) in list" :key="index">
          <div class="line" v-if="index !== 5"></div>
          <span>
            <el-dropdown @command="handleCommand">
              <span>{{ item.name }}</span>
              <el-dropdown-menu slot="dropdown" v-if="item.selectData">
                <el-dropdown-item v-for="(v, i) in item.selectData" :key="i" :command="v.nameData"
                  style="text-align: center;">{{ v.nameData }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </li>
        <!-- <li v-for="(item, index) in nav" :key="index">
          <div class="line" v-if="index !== 5"></div>
          <span>
            <el-dropdown @command="handleCommand">
              <span>{{ item.columnsName }}</span>
              <el-dropdown-menu slot="dropdown" v-if="item.columnsInfoList">
                <div v-for="(v, i) in item.columnsInfoList" :key="i">
                  <el-dropdown-item
                    :command="v.columnsName"
                    v-if="v.isEnable === '0'"
                    >{{ v.columnsName }}</el-dropdown-item
                  >
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </li> -->
        <li class="contact">
          <img src="../assets/imgs/dianhua.png" alt="" />
          <span>020-36686566</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getColumns } from "@/api/index.js";
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      // nav导航栏数据
      list: [
        {
          name: "智慧文旅",
          path: "smartManagement",
          selectData: [
            {
              nameData: "智慧管理",
              path: "smartManagement",
            },
            {
              nameData: "智慧保护",
              path: "smartProtection",
            },
            {
              nameData: "智慧服务",
              path: "smartService",
            },
          ],
        },
        {
          name: "智慧政企",
          path: "",
          selectData: [
            {
              nameData: "智慧城市",
              path: "smartCity",
            },
            {
              nameData: "GIS+CIM+BIM",
              path: "glsClmBim",
            },
            {
              nameData: "数字政务",
              path: "DigitalGovernment",
            },
          ],
        },
        {
          name: "数字展陈",
          path: "",
          selectData: [
            {
              nameData: "展览展示",
              path: "exhibition",
            },
            {
              nameData: "视频制作",
              path: "videoProduction",
            },
            {
              nameData: "虚拟展示",
              path: "virtual",
            },
            {
              nameData: "桌面应用",
              path: "virtual",
            },
          ],
        },
        {
          name: "成功案例",
          path: "",
          selectData: [
            {
              nameData: "展览",
              path: "exhibit",
            },
            {
              nameData: "活动",
              path: "activity",
            },
            {
              nameData: "三维模型",
              path: "3Dmodel",
            },
          ],
        },
        {
          name: "产品中心",
          path: "",
          selectData: [
            {
              nameData: "软件产品",
              path: "software",
            },
            {
              nameData: "硬件产品",
              path: "hardware",
            },
          ],
        },
        {
          name: "关于我们",
          path: "",
          selectData: [
            {
              nameData: "公司简介",
              path: "companyProfile",
            },
            {
              nameData: "新闻资讯",
              path: "news",
            },
            {
              nameData: "联系我们",
              path: "connect",
            },
          ],
        },
      ],
      nav: [],
    };
  },
  created() {
    // 获取栏目
    getColumns().then((res) => {
      if (res.code === 0) {
        this.nav = res.data.content.reverse()
      }
    });
  },
  computed: {
    ...mapGetters(['show'])
  },
  methods: {
    //   nav导航选项点击
    handleCommand(command) {
      // this.$router.push({ name: command, query: { num: command } });
      if (command == "智慧管理") {
        this.$router.push({
          name: "smartManagement",
          query: { num: "smartManagement" },
        });
      } else if (command == "智慧保护") {
        this.$router.push({
          name: "smartProtection",
          query: { num: "smartProtection" },
        });
      } else if (command == "智慧服务") {
        this.$router.push({
          name: "smartService",
          query: { num: "smartService" },
        });
      } else if (command == "智慧城市") {
        this.$router.push({ name: "smartCity", query: { num: "smartCity" } });
      } else if (command == "GIS+CIM+BIM") {
        this.$router.push({ name: "glsClmBim", query: { num: "glsClmBim" } });
      } else if (command == "数字政务") {
        this.$router.push({
          name: "DigitalGovernment",
          query: { num: "DigitalGovernment" },
        });
      } else if (command == "展览展示") {
        this.$router.push({ name: "exhibition", query: { num: "exhibition" } });
      } else if (command == "桌面应用") {
        this.$router.push({ name: "desktop", query: { num: "desktop" } });
      }
      else if (command == "视频制作") {
        this.$router.push({
          name: "videoProduction",
          query: { num: "videoProduction" },
        });
      } else if (command == "虚拟展示") {
        this.$router.push({ name: "virtual", query: { num: "virtual" } });
      } else if (command == "展览") {
        this.$router.push({ name: "exhibit", query: { num: "exhibit" } });
      } else if (command == "活动") {
        this.$router.push({ name: "activity", query: { num: "activity" } });
      } else if (command == "三维模型") {
        this.$router.push({ name: "3Dmodel", query: { num: "3Dmodel" } });
      } else if (command == "软件产品") {
        this.$router.push({ name: "software", query: { num: "software" } });
      } else if (command == "硬件产品") {
        this.$router.push({ name: "hardware", query: { num: "hardware" } });
      } else if (command == "公司简介") {
        this.$router.push({
          name: "companyProfile",
          query: { num: "companyProfile" },
        });
      } else if (command == "新闻资讯") {
        this.$router.push({ name: "news", query: { num: "news" } });
        this.$store.commit('ip/SET_THOW', false)
      } else if (command == "联系我们") {
        this.$router.push({ name: "connect", query: { num: "connect" } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  // width: 380px;
  height: 56px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

/deep/ .list-tilte {
  ul {
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: right;
    align-items: center;

    li {
      margin: 0 20px;
      color: #333333;
      position: relative;
      cursor: pointer;

      span {
        font-size: 16px;

        /deep/ .el-dropdown-menu .el-dropdown-item {
          font-size: 16px;
        }
      }

      .front {
        font-size: 16px;
        color: #606266;
      }
    }

    li:nth-child(1) {
      font-size: 18px;
    }

    .line {
      position: absolute;
      right: -12px;
      top: 20px;
      height: 20px;
      border-right: 1px solid #ccc;
      z-index: 100;
    }
  }

  .contact {
    margin-left: 10px;
    display: flex;
    justify-content: left;
    align-items: center;

    img {
      display: inline-block;
      width: 35px;
      height: 35px;
    }

    span {
      display: inline-block;
      margin-left: 10px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0px;
      color: #333333;
    }
  }
}

/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #e43838;
  color: #ffffff;
}

/deep/ .el-dropdown {
  font-size: 16px;
}

/deep/ .el-dropdown-menu__item {
  font-size: 14px;
}
</style>