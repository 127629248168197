import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import plugin from '../src/utils/plugin'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/base.scss'
import 'amfe-flexible';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import '@/permission'
Vue.use(plugin);
Vue.use(VueVideoPlayer);
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'bc4d824e9c0fef9cc375e45deace61d9' //官方提供的ak秘钥
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
