import axios from 'axios'
import qs from "qs"
// http://192.168.1.245:8095
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000
})

//支持formData其他提交形式
service.defaults.transformRequest = [function (data, headers) {
    if (headers['Content-Type'] === 'application/json') {
      // 把一个参数对象格式化为一个字符串
      return JSON.stringify(data)
    } else if (headers['Content-Type'] === 'multipart/form-data;charset=UTF-8') {
      return data
    } else {
      /* headers['Content-Type'] = 'application/json' */
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    return qs.stringify(data)
  
  }]

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        return config // 必须返回配置
    }, (error) => {
        // 这里处理一些请求错误的情况
        return Promise.reject(error)
    })
// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data
        //  如果成功 表示业务执行成功 直接返回data
        if (res.code !== 0) {
            Message({
                message: res.msg,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.msg))
        } else {
            return res
        }
    }, (error) => {
        // 这里处理一些reponse出错时的逻辑
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service